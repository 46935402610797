import workLocationDescriptionPopover from "../../directives/work-location-description-popover.html";
import expensesDescriptionPopover from "../../directives/expenses-description-popover.html";
export default class DealEditModalCtrl {
  private companiesDocumentsList: Document[];;
  private workLocationDescriptionPopover: string;
  private expensesDescriptionPopover: string;
  private deal: any;
  private probations: any; 
  private managerContactEntries: any;
  private availableManagerContactEntries: any;
  private dealReconciliationEmail: any;
  private onSaveError: () => void;
  private saving: boolean;
  private force_validation: boolean;
  private dealForm: ng.IFormController;;
  private dismiss: () => void;
  private registerSaveHotkey: (callback: () => void) => void;;
  private resolve: any;

  static $inject = ["$scope",
                    "$rootScope",
                    "$filter",
                    "$templateCache",
                    "CompaniesFactory",
                    "EmployeesFactory",
                    "EditUtilMixin",
                    "ProvFactory",
                    "clientHourlyExpensesOptions",
                    "deliveryOptions",
                    "periodOfPaymentOptions",
                    "internalJobTypes",
                    "entourageSubsidiaries",
                    "ContractDraftFactory",
                    "$q"];

  constructor (
    private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $filter: ng.IFilterService,
    private $templateCache: ng.ITemplateCacheService,
    private Company: any,
    private Employee: any,
    private EditUtilMixin: any,
    private Provision: any,
    private clientHourlyExpensesOptions: any,
    private deliveryOptions: any,
    private periodOfPaymentOptions: any,
    private internalJobTypes: any,
    private entourageSubsidiaries: any,
    private ContractDraft: any,
    private $q: ng.IQService,
  )  {
    this.companiesDocumentsList;
    this.workLocationDescriptionPopover = "work-location-description-popover";
    this.expensesDescriptionPopover = "expenses-description-popover";
    $templateCache.put(this.expensesDescriptionPopover, expensesDescriptionPopover);
    $templateCache.put(this.workLocationDescriptionPopover, workLocationDescriptionPopover);

    angular.extend(this, {
      clientHourlyExpensesOptions: clientHourlyExpensesOptions,
      deliveryOptions: deliveryOptions,
      periodOfPaymentOptions: periodOfPaymentOptions,
      internalJobTypes: internalJobTypes,
      entourageSubsidiaries: entourageSubsidiaries,
    });

    this.$scope.$on("modal.closing", (event) => {
      if (this.deal.unsnappedChanges()) {
        if (window.confirm(this.$filter("translate")("CLOSING_WINDOW_CONFIRMATION"))) {
          this.deal.rollback(-1);
        } else {
          event.preventDefault();
        }
      }
    });
    this.savingSuccess = this.savingSuccess.bind(this);
  }

  public extend(resolve) {
    this.deal = resolve.deal;
    this.probations = resolve.probations;
  }

  public $onInit () {
    angular.extend(this, this.EditUtilMixin);

    this.extend(this.resolve);
    this.$rootScope.$broadcast("deal-form-opened", this.deal);

    this.deal.allowFeedback = _.isNull(this.deal.acknowledgementEmailSentAt);
    this.deal.recipient = this.deal.recipient || this.deal.project.invoiceRecipient;
    if (this.deal.isPermanentEmploymentInternal) {
      this.deal.periodOfPayment = 0;
    }

    if (!this.deal.recipientCompany && this.deal.project.company) {
      this.Company.get(this.deal.project.company.id).then( (company) => {
        this.companiesDocumentsList = company.documents;
        company.transactionBasis.map((transactions) => {
          this.companiesDocumentsList.push(...transactions.documents);
        });
      });
      (<any>this.Employee).query({ company_id: this.deal.project.company.id }).then( (employees) => {
        this.deal.recipient = _.first(employees);
        this.deal.snapshot();
      });
    }

    if (this.deal.recipientCompany) {
      this.Company.get(this.deal.recipientCompany.id).then( (company) => {
        this.companiesDocumentsList = company.documents;
        company.transactionBasis.map((transactions) => {
          this.companiesDocumentsList.push(...transactions.documents);
        });
      });
    }

    this.deal.snapshot();

    this.managerContactEntries = _.each(this.deal.project.employees,  (manager) => {
      manager.$hasContactAddress = !_.isEmpty(manager.workEmail || manager.privateEmail);
    });
    this.availableManagerContactEntries = _.where(this.managerContactEntries, { $hasContactAddress: true });
    this.dealReconciliationEmail = {
      employees: this.managerContactEntries,
      callback: null,
    };

    // perform after everything is rendered
    this.registerSaveHotkey(this.saveChanges);
  };

  public confirmPurchaseOrderNumber = _.once( () => {
    return !window.confirm(this.$filter("translate")("NO_PURCHASE_ORDNER_NUMBER_SAVED"));
  });

  public savingSuccess(newDeal) {
    if (this.deal.jobType.id != newDeal.jobType.id) {
      window.alert(this.$filter("translate")("PROJECT_TYPE_CHANGED_NOTICE"));
    }

    _.extend(this.deal, newDeal);
    this.deal.snapshot();

    if (this.deal.closing) {
      this.getCurrentContractDraftCdsJobData();
    } else if (!this.deal.failed) {
      if (!this.deal.hasSapId) {
        this.deal.pollSapJobData();
      } else if (this.deal.allCandidatesInSap) {
        this.deal.pollCdsJobData();
      }
    } else {
      this.onSaveError.bind(this)();
    }
    this.saving = false;
  }

  public getCurrentContractDraftCdsJobData () {
    this.deal.provisions.forEach( (provision) => {
      const currentContractDraft = provision.candidate.contractDraft;
      if (currentContractDraft && currentContractDraft.$errors == null) {
        currentContractDraft.pollCdsJobData();
      }
    });
  };

  public saveCorrespondingContractDraftPromise (provision) {
    let currentContractDraft = provision.candidate.contractDraft;
    return currentContractDraft.save().then( (savedContractDraft) => {
      _.extend(currentContractDraft, savedContractDraft);
      currentContractDraft.snapshot();

      if (currentContractDraft.id != null) {
        if (currentContractDraft.failed && !currentContractDraft.generating) {
          this.onSaveError.bind(this)();
        }
      }
    }, this.onSaveError.bind(this));
  };

  public saveChanges () {
    if (!this.deal.id && !this.deal.closing && !this.deal.purchaseOrderNumber && this.confirmPurchaseOrderNumber()) {
      return;
    }
    this.force_validation = false;
    if (this.dealForm.$invalid && !this.deal.closing) {
      window.alert(this.$filter("translate")("ERRORS_IN_FORM_NOTICE"));
      this.force_validation = true;
    } else {
      if (this.saving) {
        return false;
      }
      this.saving = true;

      // save corresponding contract drafts

      const contractDraftPromises = this.deal.provisions.map( (provision) => {
        if (provision.candidate.contractDraft) {
          return this.saveCorrespondingContractDraftPromise(provision);
        }
        return null;
      });

      this.$q.all(contractDraftPromises).then( () => {
        if (!this.deal.isEmployeeLeasingEnabled()) {
          this.deal.employeeLeasing = false;
        }
        this.deal.save().then(this.savingSuccess, this.onSaveError.bind(this));
      });
    }
  };

  public cancel () {
    this.dismiss();
  };

  public addProvision () {
    if (!this.deal.id) {
      window.alert(this.$filter("translate")("DEAL_NEEDS_SAVING_BEFORE_ADDING_CANDIDATES_NOTICE"));
      return;
    }

    var excludes = _.map(this.deal.provisions,  (provision) => {
      return provision.candidate;
    });
    this.deal.project.openChooseCandidateModal({ autocomplete: true, excludes: excludes }).then( (cpjoin) => {
      var deal_params = { deal: { candidatesProjectId: cpjoin.id } };
      this.Provision.$get(this.deal.$url("/provisions/new.json"), deal_params).then( (provision) => {
        this.deal.provisions.push(provision);
      });
    });
  };

  public deleteProvision (index) {
    this.deal.provisions.splice(index, 1);
  };

  public addContractDraftToCandidate (provision) {
    if (provision.candidate.hasUnfinalizedContractDrafts) {
      window.alert(this.$filter("translate")("CANDIDATE_HAS_UNFINALIZED_CONTRACTS_NOTICE"));
      window.rollbar.error("Tried to create contract for candidate which is in a contract creation process", { candidate_id: provision.candidate.id });
      return;
    }
    this.ContractDraft.createContractDraft(provision).then( (newContractDraft) => {
      provision.candidate.contractDraft = newContractDraft;
      var targetId = "candidate_" + provision.candidate.id + "_contract_draft";
      var element = document.getElementById(targetId);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    });
  };

  public turnClosingToDeal () {
    this.deal.turnClosingToDeal().then( (newDeal) =>  {
      if (newDeal) {
        _.extend(this.deal, newDeal);
      }
    });
  };
}
