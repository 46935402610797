//= require ../candidates.module.js

angular.module("candidates").service("CandidatesFactory", [
  "RailsResource",
  "RailsResourceSnapshotsMixin",
  "CandidatesModalMixin",
  "PersonModalMixin",
  "ModalMixin",
  "TextkernelModalMixin",
  "ActionsFactory",
  "$state",
  "$rootScope",
  "$http",
  "$filter",
  "Highlighter",
  "railsSerializer",
  function (
    RailsResource,
    RailsResourceSnapshotsMixin,
    CandidatesModalMixin,
    PersonModalMixin,
    ModalMixin,
    TextkernelModalMixin,
    Action,
    $state,
    $rootScope,
    $http,
    $filter,
    Highlighter,
    railsSerializer
  ) {
    function Candidate() {
      (<any>Candidate).__super__.constructor.apply(this, arguments);

      this.resourceType = "Candidate";
      this.serviceName = "CandidatesFactory";
      this.candidatesProjects = this.candidatesProjects || [];

      this.allDocuments = function () {
        if (!this.$allDocuments) {
          this.$allDocuments = _.clone(this.documents);
        }

        return this.$allDocuments;
      };

      // returns a deep copy of a candidate as OBJECT
      this.deepCopy = function () {
        return angular.copy(this);
      };

      this.extendByDiff = function (candidateDiffData) {
        var mappedTags = _.map(candidateDiffData.taggings, function (tagging) {
          return tagging.tag;
        });

        angular.extend(this, _.omit(candidateDiffData, "changes"), {
          tags: _.reject(mappedTags, _.matcher({ type: "ComputerSkill" })),
          computerSkills: _.where(mappedTags, { type: "ComputerSkill" }),
          workMobilePhone: $filter("phoneNumber")(candidateDiffData.workMobilePhone),
          privateMobilePhone: $filter("phoneNumber")(candidateDiffData.privateMobilePhone),
          workPhone: $filter("phoneNumber")(candidateDiffData.workPhone),
          privatePhone: $filter("phoneNumber")(candidateDiffData.privatePhone),
        });

        this.$attributeChanges = [];
        _.chain(this._prepSnapshot())
          .keys()
          .omit("$attribute_changes")
          .each(
            function (attrKey) {
              this.attributeChanged(attrKey);
            }.bind(this)
          );
      };

      this.attributeChanged = function (attribute) {
        this.$attributeChanges = this.$attributeChanges || {};

        if (_.isUndefined(this.$attributeChanges[attribute])) {
          if (_.isEmpty(this.$snapshots)) {
            this.$attributeChanges[attribute] = false;
          } else {
            this.$attributeChanges[attribute] = !angular.equals(
              _.last(this.$snapshots)[attribute],
              this._prepSnapshot()[attribute]
            );
          }
        }
        return this.$attributeChanges[attribute];
      };

      this.mapSearchScopesToHumanReadable = Candidate.mapSearchScopesToHumanReadable;

      this.addToProject = function (project) {
        project.addCandidate(this);
      };

      this.currentProjects = function () {
        return _.chain(this.candidatesProjects)
          .where({ deleted: false })
          .reject({ project: null })
          .map(function (cpjoin) {
            return cpjoin.project;
          })
          .value();
      }.bind(this);

      this.createNewAction = function (actionTypeIdentifier) {
        Action.createNewAction(this, actionTypeIdentifier);
      };

      this.params = function () {
        return { candidate_id: this.id };
      };

      this.getDefaultEmailAddress = function () {
        return this.privateEmail;
      };

      this.createMessage = function (actionRoute, emailParams) {
        var params = angular.merge({ id: this.id }, emailParams);
        return $http.post("candidates/" + this.id + "/" + actionRoute + ".json", { candidate: params });
      };

      this.sendDocument = function (actionRoute, infoPresentationEmail) {
        (<any>Candidate).$post("candidates/" + this.id + "/" + actionRoute + ".json", infoPresentationEmail).then(
          function (resCandidate) {
            $rootScope.$broadcast("candidate_action_changed_" + this.id);
          }.bind(this)
        );
      }.bind(this);

      this.saveCustomNote = function (action) {
        Action.saveCustomNote(this, action);
      }.bind(this);

      this.toString = function () {
        return JSON.stringify(this);
      }.bind(this);

      this.filterDepartments = function () {
        return { job_category_ids: { job_category_ids: _.pluck(this.jobCategories, "id") } };
      }.bind(this);
    }

    Candidate.transformResultHandlerOk = function (result) {
      if (result) {
        $state.go("root.candidates.show", result.params());
        if (result.notTransmitted) {
          window.alert(
            $filter("translate")("REJECTED_ATTRIBUTES") +
              ": " +
              _.map(result.notTransmitted, function (val, key) {
                return key + ": " + val;
              })
          );
        }
      }
    };

    Candidate.transformResultHandlerError = function (result) {
      if (result.status != 409) {
        window.rollbar.error("Generating candidate from employee failed", { status: result.status });
      } else {
        window.alert($filter("translate")("CANDIDATE_EXISTS_ALREADY"));
        window.rollbar.error("Candidate exists already", { status: result.status });
      }
    };

    Candidate.mapSearchScopesToHumanReadable = function (scopeDescriptor) {
      switch (scopeDescriptor) {
        case "fullNameWithTitle.standard":
        case "fullNameReverseWithTitle.standard":
          return "MAP_READ_EXACT_NAME";
        case "fullNameReverseWithTitle.highlight":
          return "MAP_READ_INEXACT_NAME";
        case "address":
          return "MAP_READ_ADDRESS";
        case "privateEmail":
          return "MAP_READ_EMAIL_PRIVATE";
        case "workEmail":
          return "MAP_READ_EMAIL_WORK";
        case "privatePhone":
          return "MAP_READ_TELEPHONE_PRIVATE";
        case "privateMobilePhone":
          return "MAP_READ_MOBILE_PRIVATE";
        case "workPhone":
          return "MAP_READ_TELEPHONE_WORK";
        case "workMobilePhone":
          return "MAP_READ_MOBILE_WORK";
        case "tags.name":
          return "MAP_READ_TAG";
        case "jobCategories.name":
          return "MAP_READ_JOB_CATEGORIES";
        case "allActions.description.de":
          return "MAP_READ_HISTORY";
        case "jobTitle":
          return "MAP_READ_CURRENT_POSITION";
        case "wantedJobTitle":
          return "MAP_READ_WANTED_POSITION";
        case "candidateDocuments.extractedText":
        case "candidateDocuments.extractedText.de":
          return "MAP_READ_DOCUMENTS";
        case "responsibleUser.symbole":
        case "responsibleUser.fullName.notAnalyzed":
          return "MAP_READ_RESPONSIBLE_USER";
        case "restrictionNotice.standard":
          return "MAP_READ_BLOCKING_NOTICE";
        case "company.name":
          return "MAP_READ_COMPANY";
        case "id":
          return "MAP_READ_ID";
        case "restriction_notice":
          return "MAP_READ_BLOCKING_NOTICE";
        default:
          return undefined;
      }
    };

    Candidate.profileSources = [
      { id: "unknown_source", name: $filter("translate")("SOURCE_UNKNOWN"), selectable: false },
      { id: "entourage_homepage", name: $filter("translate")("SOURCE_ENTOURAGE"), selectable: true },
      { id: "linked_in", name: $filter("translate")("SOURCE_LINKEDIN"), selectable: true },
      { id: "xing", name: $filter("translate")("SOURCE_XING"), selectable: true },
      { id: "recommendation", name: $filter("translate")("SOURCE_RECOMMENDATION"), selectable: false },
      { id: "email", name: $filter("translate")("SOURCE_EMAIL"), selectable: false },
    ];

    Candidate.duplicatesWatchFields = [
      "firstName",
      "lastName",
      "privatePhone",
      "privateMobilePhone",
      "privateEmail",
      "workPhone",
      "workMobilePhone",
      "workEmail",
    ];

    Candidate.attributeNames = {
      firstName: "ATTR_FIRST_NAME",
      lastName: "ATTR_LAST_NAME",
      privateEmail: "ATTR_EMAIL_PRIVATE",
      privateMobilePhone: "ATTR_MOBILE_PRIVATE",
      taggings: "ATTR_TAGS",
      birthDate: "ATTR_BIRTHDATE",
      hobbies: "ATTR_HOBBIES",
      street: "ATTR_STREET",
      zipCode: "ATTR_POST_CODE",
      city: "ATTR_CITY",
      gender: "ATTR_GENDER",
      jobTitle: "ATTR_CURRENT_POSITION",
      linkedinUrl: "ATTR_LINKEDIN",
      xingUrl: "ATTR_XING",
      homepage: "ATTR_HOMEPAGE",
      languageSkills: "ATTR_LANGUAGES",
      personsNationalities: "ATTR_NATIONALITY",
      workingRelationships: "ATTR_EMPLOYER",
      personsGraduations: "ATTR_EDUCATION",
      privateCountryId: "ATTR_COUNTRY_ADDRESS",
    };

    Candidate.mapAttribute = function (attr) {
      return $filter("translate")(Candidate.attributeNames[attr]);
    };

    Candidate.defaultTableAttributes = {
      id: {
        title: "TABLE_ID",
        show: true,
        pinned: true,
        menu: true,
        class: "id",
        sortField: "id",
      },
      name: {
        title: "TABLE_NAME",
        show: true,
        fixedWidth: true,
        class: "title",
        sortField: "full_name_reverse_with_title.not_analyzed",
      },
      company: {
        title: "TABLE_COMPANY",
        show: true,
        class: "title",
        sortField: "company.name.not_analyzed",
      },
      priority: {
        title: "TABLE_PRIORITY",
        show: false,
        class: "priority",
        sortField: "priority",
      },
      tags: {
        title: "TABLE_TAGS",
        show: true,
        class: "tags",
      },
      city: {
        title: "TABLE_RESIDENCE",
        show: true,
        class: "city",
        sortField: "private_city",
      },
      kpis: {
        title: "TABLE_KPIS",
        show: true,
        class: "kpis",
      },
      responsibleUser: {
        title: "TABLE_RESPONSIBLE_USER",
        show: true,
        class: "responsible",
        sortField: "responsible_user.full_name.not_analyzed",
      },
      languages: {
        title: "TABLE_LANGUAGES",
        show: false,
        class: "language",
      },
      documents: {
        title: "TABLE_DOCUMENTS",
        show: false,
        class: "document",
        sortField: "candidate_documents_count",
      },
      createdAt: {
        title: "TABLE_CREATED_AT",
        show: false,
        class: "date",
        sortField: "created_at",
      },
    };

    Candidate.requirements = {
      yellow: ["REQ_YELLOW_NAME", "REQ_YELLOW_TELEPHONE", "REQ_YELLOW_EMAIL", "REQ_YELLOW_CV"],
      green: [
        "REQ_GREEN_TAGS",
        "REQ_GREEN_WANTS_AWAY",
        "REQ_GREEN_GEOFLEX",
        "REQ_GREEN_JOB_CATEGORY",
        "REQ_GREEN_LATEST_INCOME",
      ],
    };

    RailsResource.extendTo(Candidate);
    (<any>Candidate).configure({
      url: "/candidates",
      name: "candidate",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.nestedAttribute("languageSkills");
        this.nestedAttribute("phase");
        this.resource("phase", "ActionsFactory");
        this.nestedAttribute("actions");
        this.resource("actions", "ActionsFactory");
        this.nestedAttribute("candidatesProjects");
        this.serializeWith("maritalStatus", "MaritalStatusesSerializer");
        this.resource("candidatesProjects", "CandidatesProjectsFactory");
        this.resource("contractDraft", "ContractDraftFactory");
        this.resource("employee", "EmployeesFactory");
        this.serializeWith("tags", "TagSerializer");
        this.serializeWith("regions", "RegionSerializer");
        this.resource("expertProfiles", "ExpertProfileFactory");
        this.serializeWith("jobCategories", "JobCategoriesSerializer");
        this.serializeWith("jobTypes", "JobTypesSerializer");
        this.rename("documents", "candidateDocuments");
        this.resource("documents", "DocumentsFactory");
        this.resource("workingRelationships", "WorkingRelationshipsFactory");
        this.resource("personsGraduations", "PersonsGraduationsFactory");

        this.serializeWith("organisationUnit", "OrganisationUnitSerializer");

        this.serializeWith("birthdate", "DateSerializer");

        this.serializeWith("salaryIstFix", "MoneySerializer");
        this.serializeWith("salaryIstVariable", "MoneySerializer");
        this.serializeWith("salarySollFixMax", "MoneySerializer");
        this.serializeWith("salarySollFixMin", "MoneySerializer");
        this.serializeWith("salarySollVariableMax", "MoneySerializer");
        this.serializeWith("salarySollVariableMin", "MoneySerializer");
        this.serializeWith("salaryIstPerHour", "MoneySerializer");
        this.serializeWith("salarySollPerHour", "MoneySerializer");
        this.serializeWith("internalCost", "MoneySerializer");
        this.serializeWith("internalCostVk", "MoneySerializer");
        this.serializeWith("interCompanyRate", "MoneySerializer");

        this.serializeWith("wantedJobDescription", "SanitizeSerializer");
        this.serializeWith("jobChangeMotivationDescription", "SanitizeSerializer");
        this.serializeWith("openApplicationsDescription", "SanitizeSerializer");
        this.serializeWith("exclusionCriterionDescription", "SanitizeSerializer");
        this.serializeWith("coreCompetenciesDescription", "SanitizeSerializer");
        this.serializeWith("skillsDescription", "SanitizeSerializer");

        this.serializeWith("priority", "PrioritySerializer");

        this.serializeWith("isAvailableFrom", "FreeDateSerializer");
        this.serializeWith("openForInterviewsFrom", "FreeDateSerializer");

        this.exclude("$snapshots");
      }),
    });
    (<any>Candidate).extend(RailsResourceSnapshotsMixin);
    (<any>Candidate).extend(CandidatesModalMixin);
    (<any>Candidate).extend(ModalMixin);
    (<any>Candidate).extend(PersonModalMixin);
    (<any>Candidate).extend(TextkernelModalMixin);
    (<any>Candidate).extend(Highlighter);

    return Candidate;
  },
]);
