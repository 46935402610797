//= require ../provisions.module.js

angular.module("provisions").service("ProvFactory", [
  "RailsResource",
  "railsSerializer",
  function (RailsResource, railsSerializer) {
    function Provision() {
      (<any>Provision).__super__.constructor.apply(this, arguments);

      this.isCreateContractDraftButtonEnabled = function (deal) {
        return deal.closing && deal.needsContractDraft && !this.isFreelancer;
      };

      this.contractDraftParams = function () {
        const contractDraftParams = {
          contract_draft: {
            candidate_id: this.candidate.id,
            provision_id: this.id,
            start_date: this.startDate,
            end_date: this.endDate,
            vacation_days: this.holidays,
            hours_per_week: this.hoursPerWeek,
            temporary: false,
            organisation_unit_id: this.candidate.organisationUnit?.id,
          },
        };
        return contractDraftParams;
      }.bind(this);
    }

    RailsResource.extendTo(Provision);
    (<any>Provision).configure({
      url: "/provisions",
      name: "provision",
      interceptors: ["UnpackEnvelopeInterceptor"],
      serializer: railsSerializer(function () {
        this.resource("candidate", "CandidatesFactory");
        this.serializeWith("probation", "CancelationPeriodSerializer");

        this.resource("candidateProvisionConsultant", "UsersFactory");
        this.resource("customerProvisionConsultant", "UsersFactory");
        this.resource("candidateProcessProvisionConsultant", "UsersFactory");
        this.resource("processProvisionConsultant", "UsersFactory");

        this.serializeWith("grossPerAnno", "MoneySerializer");
        this.serializeWith("additionalCompensation", "MoneySerializer");
        this.serializeWith("fixedAmountCoveredIst", "MoneySerializer");
        this.serializeWith("hoursClient", "MoneySerializer");
        this.serializeWith("hoursFreelancer", "MoneySerializer");
        this.serializeWith("internalCost", "MoneySerializer");
        this.serializeWith("assessmentBasis", "MoneySerializer");
        this.serializeWith("amountCoveredIst", "MoneySerializer");
        this.serializeWith("netSales", "MoneySerializer");

        this.serializeWith("startDate", "DateSerializer");
        this.serializeWith("endDate", "DateSerializer");
      }),
    });

    return Provision;
  },
]);
