import { IFilterService, IHttpParamSerializer, IQService, IRootScopeService } from "angular";
import moment from "moment";

export default class DashboardsCtrl {
  public canceler: ng.IDeferred<any>;
  public loading: boolean;
  public ranges: {
    LAST_WEEK: [moment.Moment, moment.Moment],
    CURRENT_WEEK: [moment.Moment, moment.Moment],
    NEXT_WEEK: [moment.Moment, moment.Moment],
    LAST_MONTH: [moment.Moment, moment.Moment],
    CURRENT_MONTH: [moment.Moment, moment.Moment],
    "1_QUARTER": [moment.Moment, moment.Moment], 
    "2_QUARTER": [moment.Moment, moment.Moment], 
    "3_QUARTER": [moment.Moment, moment.Moment], 
    "4_QUARTER": [moment.Moment, moment.Moment], 
    CURRENT_YEAR: [moment.Moment, moment.Moment],
    LAST_YEAR: [moment.Moment, moment.Moment],
  };
  public user: any;
  public allowProjectReporting: boolean;
  public allowVWSOverview: boolean;
  public currencies: Array<{isoCode: string, isoNumeric: number}>;
  public lastMviewsRefresh: moment.Moment;
  public isSalesView: boolean;
  public isLegacyCalculation: boolean;
  public kpisQuery: {
    dateRange: { startDate: moment.Moment, endDate: moment.Moment },
    userQuery?: any,
    currency?: any,
    kpis?: any, 
  };
  public datePickerOptions: { date: { startDate: moment.Moment, endDate: moment.Moment }, userQuery: { users: any[] }, options: any};

  static $inject = [
    "$rootScope",
    "$q",
    "SidebarFactory",
    "ProjectReportingsFactory",
    "Session",
    "$httpParamSerializer",
    "Currencies",
    "daterangepickerOptions",
    "$filter"
  ];

  constructor(
    private $rootScope: IRootScopeService, 
    private $q: IQService, 
    private Sidebar: any, 
    private ProjectReporting: any, 
    private Session: any, 
    private $httpParamSerializer: IHttpParamSerializer, 
    private Currencies: any,
    private daterangepickerOptions: any,
    private $filter: IFilterService, 
  ) {
    this.canceler = this.$q.defer();
    this.ranges = {
      LAST_WEEK: [moment().startOf("isoWeek").subtract(1, "weeks"), moment().startOf("isoWeek").subtract(1, "days")],
      CURRENT_WEEK: [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
      NEXT_WEEK: [moment().startOf("isoWeek").add(1, "weeks"), moment().endOf("isoWeek").add(1, "weeks")],
      LAST_MONTH: [moment().startOf("month").subtract(1, "months"), moment().startOf("month").subtract(1, "days")],
      CURRENT_MONTH: [moment().startOf("month"), moment()],
      "1_QUARTER": [moment().startOf("year"), moment().startOf("year").add(1, "quarter").subtract(1, "days")],
      "2_QUARTER": [
        moment().startOf("year").add(1, "quarter"),
        moment().startOf("year").add(2, "quarter").subtract(1, "days"),
      ],
      "3_QUARTER": [
        moment().startOf("year").add(2, "quarter"),
        moment().startOf("year").add(3, "quarter").subtract(1, "days"),
      ],
      "4_QUARTER": [moment().startOf("year").add(3, "quarter"), moment().endOf("year")],
      CURRENT_YEAR: [moment().dayOfYear(1), moment()],
      LAST_YEAR: [moment().subtract(1, "year").dayOfYear(1), moment().subtract(1, "year").endOf("year")],
    };
    
    this.updateDashboardProjectReporting = this.updateDashboardProjectReporting.bind(this);
  }

  public $onInit () {
    this.user = this.Session.user;
    this.allowProjectReporting = this.user.allowProjectReporting;
    this.allowVWSOverview = this.user.allowVwsOverview;

    this.currencies = this.Currencies;

    this.kpisQuery = {
      dateRange: {
        startDate: moment().startOf("isoWeek"),
        endDate: moment().endOf("isoWeek"),
      },
    };

    this.datePickerOptions = {
      date: this.kpisQuery.dateRange,
      userQuery: { users: [this.user] },
      options: _.extend(this.daterangepickerOptions, {
        timePicker: false,
        ranges: _.object(
          _.map(this.ranges, (value, key) => {
            return [this.$filter("translate")(key), value];
          })
        ),
        eventHandlers: { "apply.daterangepicker": _.partial(this.updateDashboardProjectReporting, true) },
      }),
    };

    // initial load Dashboard data
    this.updateDashboardProjectReporting();

    // TODO: doesn't seems to work, fix this
    this.Sidebar.closeAll();
  };

  public queryIsValid() {
    var dateRange = this.datePickerOptions.date;

    return dateRange.startDate.isValid() && dateRange.endDate.isValid();
  };

  public getDateRangeOptions() {
    var dateRange = this.datePickerOptions.date;
    // Workaround for DatetimePicker - always return moment object instead of Date (cannot handle timezone serialization)
    return {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };
  };

  public getDashboardFilterParams(currencyChanged?: boolean) {
    return {
      "user_ids[]": _.pluck(this.kpisQuery.userQuery.users, "id"),
      team_id: _.propertyOf(this.kpisQuery.userQuery.team)("id"),
      all_active: this.kpisQuery.userQuery.allActive,
      date: JSON.stringify(this.kpisQuery.dateRange),
      currency: currencyChanged ? this.kpisQuery.currency : undefined,
    };
  };

  public projectReportingRequest(params) {
    if (this.loading) {
      return;
    }

    this.$rootScope.$broadcast("dashboard-query-timeout");

    this.canceler.resolve();
    this.canceler = this.$q.defer();

    this.loading = true;

    return this.ProjectReporting.$http({
      url: "project_reporting/time_range",
      params: params,
      headers: { Accept: "application/json" },
      timeout: this.canceler.promise,
    }).then(
      (result) => {
        this.kpisQuery.kpis = result;
        this.kpisQuery.currency = result.currency;
        this.lastMviewsRefresh = result.kpis.lastMviewsRefreshes.projectDealAction
          ? moment(result.kpis.lastMviewsRefreshes.projectDealAction)
          : undefined;

        var updatedParams = this.getDashboardFilterParams(true);

        this.$rootScope.$broadcast("dashboard-query-changed", updatedParams);

        this.loading = false;
      },
      (result) => {
        this.loading = false;
        window.rollbar.error("Dashboard loading error", { status: result });

        window.alert(this.$filter("translate")("LOADING_ERROR_NOTICE"));
      }
    );
  };

  public updateDashboardProjectReporting(currencyChanged?: boolean) {
    if (!this.queryIsValid()) {
      return;
    }
    this.kpisQuery.dateRange = this.getDateRangeOptions();
    this.kpisQuery.userQuery = this.datePickerOptions.userQuery;
    var params = this.getDashboardFilterParams(currencyChanged);

    this.isSalesView =
      this.kpisQuery.userQuery.users && this.kpisQuery.userQuery.users.length == 1
        ? _.first(this.kpisQuery.userQuery.users).isSales()
        : true;

    this.isLegacyCalculation = moment(this.kpisQuery.dateRange.startDate).year() < 2020;

    this.projectReportingRequest(params);
  };

  public editKPIOperative() {
    if (this.kpisQuery.userQuery.users && this.kpisQuery.userQuery.users.length == 1) {
      new this.ProjectReporting()
        .edit(_.first(this.kpisQuery.userQuery.users))
        .then(this.updateDashboardProjectReporting);
    } else {
      window.alert(this.$filter("translate")("SELECT_SINGLE_USER_NOTICE"));
    }
  };

  public downloadVWSOverview () {
    window.open(
      "/project_reporting/vws_overview.xlsx?" + this.$httpParamSerializer(this.getDashboardFilterParams(true)),
      "_blank"
    );
  };
};

