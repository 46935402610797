export class candidateSearchModalCtrl {
  public cpjoins: any[];
  public cpjoin: any;
  public autocomplete: any;
  public foundCpjoins: any[];
  public loading: boolean;
  private cancelers: ng.IDeferred<any>[] = [];
  public excludes: any;
  public project: any;
  public resolve: {
    options: any;
  };

  public modalInstance: angular.ui.bootstrap.IModalInstanceService;

  static $inject = ["Autocompletes", "$q"];
  constructor(private Autocompletes: any, private $q: ng.IQService) {}

  public $onInit() {
    _.extend(this, this.resolve.options);

    this.cpjoins = _.filter(this.cpjoins, (obj) => {
      return !_.findWhere(this.excludes, { id: obj.candidate.id });
    });
    this.cpjoin = _.first(this.cpjoins);

    if (this.autocomplete) {
      this.foundCpjoins = [];
      this.loading = false;
      this.cancelers = [];
    }
  }

  public searchCpjoins(viewValue: any) {
    this.loading = true;
    _.invoke(this.cancelers, "resolve");
    this.cancelers.push(this.$q.defer());

    this.Autocompletes.autocomplete(viewValue, "candidate_by_project", _.last(this.cancelers), "project", {
      project_id: this.project.id,
    }).then((results: any) => {
      if (!results) {
        return;
      }

      results.splice(0, 1); // remove query string from results list

      this.foundCpjoins = _.chain(results)
        .map((result: any) => {
          switch (result.resource_type) {
            case "candidates_projects":
              result.name = result.candidates_project.candidate.name;
              return _.extend(result.candidates_project, {
                resource_type: "Kandidat",
              });
            default:
              result.name = result.highlight[0];
              return _.extend(result, {
                resource_type: "Query",
              });
          }
        })
        .filter((obj: any) => {
          return !_.findWhere(this.excludes, { id: obj.candidate.id });
        })
        .value();
      this.loading = false;
    });
  }

  public cancel() {
    this.modalInstance.dismiss("cancel");
  }

  public chooseCandidate() {
    this.modalInstance.close(this.cpjoin);
  }
}
